import {Component, Input, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-activity-edition-true-false',
    templateUrl: './activity-edition-true-false.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ActivityEditionTrueFalseComponent /*implements OnChanges*/ {
    @Input() control?: FormControl;
}
