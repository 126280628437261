import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-activity-edition-horizontal-selector',
  templateUrl: './activity-edition-horizontal-selector.component.html',
  styleUrls: ['./activity-edition-horizontal-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityEditionHorizontalSelectorComponent<T extends string> {

    @Input() choices: {[key in T]: {label:string, icon: string}};
    @Input() selected: T;
    @Output() selectedChange = new EventEmitter<T>();

    get choicesArray() {
        if (!this.choices) {
            return [];
        }

        return Object.entries(this.choices).map(([key]) => ({key, value: this.choices[key]}));
    }

    // LOGIC

    private select(choice: T): void {
        this.selected = choice;
        this.selectedChange.emit(choice);
    }

    // EVENTS
    public onChoiceClick($event: MouseEvent, choice: T): void {
        $event.preventDefault();
        this.select(choice);
    }
}
